<template>
  <v-card>
    <v-card-title>Spreadsheet Uploader</v-card-title>
    <v-card-text>
      <v-file-input
        v-model="form.file"
        :loading="loading"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        label="Choose Excel (.xlsx) File"
      >
      </v-file-input>

      <v-alert
        v-for="err in errors"
        :key="err"
        color="warning"
      >
        {{ err }}
      </v-alert>

      <v-alert
        v-if="previewed && !errors.length"
        color="success"
      >
        The Spreadsheet Data seems fine, please click "Confirm Create".
      </v-alert>

      <v-btn
        :color="previewed ? '' : 'primary'"
        :disabled="!form.file"
        @click="preview"
      >
        Preview
      </v-btn>

      <v-btn
        v-if="previewed"
        color="primary"
        :disabled="!form.file"
        @click="confirmCreate"
      >
        Confirm Create
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { spreadSheetUpload } from '@/api/product'
import { useExtractErrors } from '@/composables'
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const form = ref({
      file: null,
    })
    const loading = ref(false)
    const previewed = ref(false)
    const errors = ref([])
    const { router } = useRouter()

    const getData = params => {
      const data = new FormData()
      data.append('file', form.value.file)
      for (const attr in params) {
        data.append(attr, params[attr])
      }

      return data
    }

    const preview = () => {
      const data = getData({ persist: 0 })
      errors.value = []
      loading.value = true
      spreadSheetUpload(data)
        .then(res => {
          previewed.value = true
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
        .finally(() => {
          loading.value = false
        })
    }
    const confirmCreate = () => {
      const data = getData({ persist: 1 })
      errors.value = []
      loading.value = true
      spreadSheetUpload(data)
        .then(res => {
          previewed.value = true
          router.push({ name: 'product-list' })
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      loading,

      errors,
      preview,
      previewed,
      confirmCreate,
    }
  },
}
</script>
